:root {
    --valid: #8822ff;
    --wrong: #ff0024;
}

.numeric_input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

input {
    min-width: 80px;
    margin: 3rem;
    font-weight: bold;
    color: var(--bg-dark);
    background-color: var(--blue);
    padding: 0.2rem;
    border: 2px solid var(--white);
    border-radius: 0.5rem;
    text-align: center;
}

:hover {
    cursor: pointer;
}


.valid {
    border-color: var(--valid);
}

.invalid {
    border-color: var(--wrong);
}

*:focus {
    outline: none;
}
