.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.button-wrap {
   flex-direction: row;
}

button {
    min-width: 120px;
    margin: 3rem;
    font-weight: bold;
    color: var(--bg-dark);
    background-color: var(--blue);
    padding: 0.7rem;
    border: 2px solid var(--white);
    border-radius: 0.5rem;
}

button:hover {
    cursor: pointer;
    background-color: var(--bg-dark);
    color: var(--blue);
}
